import React, { Component } from 'react'

   
    const DataContext = React.createContext();
   
    class DataProvider extends Component {
        state = {
            sidebarOpen:false,
            color:"black",

        }
   
        handleSidebar =()=>{
            this.setState({sidebarOpen:!this.state.sidebarOpen})
        }

      
     
 
     
  


        
        colorChanger =()=>{
          if(window.scrollY > window.innerHeight){
            this.setState({color:"white"})
          } else{
            this.setState({color:"black"})
          }
    
        }



        render() {
        return (
          <DataContext.Provider
            value={{
              ...this.state,
              handleSidebar: this.handleSidebar,
              scrollDown100:this.scrollDown100,
              colorChanger:this.colorChanger
              //functions from lettvegg start
              
            }}
          >
            {this.props.children}
          </DataContext.Provider>
        );
      }
    }


const DataConsumer = DataContext.Consumer;

export { DataProvider, DataConsumer };