import React from 'react'
import Søknad from "../Vedlegg 3 - Prosjektsøknad.pdf"

export default function Downloads() {
  return (
    <div>
  
  <p>last ned ting <a href={Søknad} download>Laste ned</a> </p>

    </div>
  )
}
