import React from 'react'
import ParticelseBackground from "../ParticleBackground";
import styled from "styled-components";
import DownArrows from "../components/DowArrows";
import logo from "../images/logo-01.png"


export default function HomeNew() {
    return (
        <StyledWrapper>
       <ParticelseBackground/>

       <div id="text_div center_all">
      <div className="center_all">
          <div className="banner">
                  <a href="/" className="logoLink">
                  <img src={logo} alt="logo" className="logoBanner"/>
                  </a>
                    </div>   
                  <div className="bannerText">

        <h1 className="custom-subTitle my-5">Innovasjon og Entreprenørskap ved UiT!</h1>
         </div>
      

      </div>
    </div>
    <div className="bottomArrow" onClick={ ()=>{
          window.scrollBy(0,window.innerHeight-window.scrollY);
        }}>
  <DownArrows cName="arrowsBlack"/>
    </div>
             
                </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
height: 100vh;

.banner{
    margin:auto;
}

.obs{
    color:red;
}
#text_div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 10px;
}

.bottomArrow{
    position: absolute;
    bottom:-0;
    margin-left: 50%;
    @media(max-width:992px){
    display: none;
}
}
h1{
    font-size: 35px;
    font-weight: 600;

}
.center_all {
    justify-content: center!important;
    align-items: center!important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
  position: absolute;
  color: black;
  text-align: center;


}
.card{

    border:none;
    @media(max-width:770px){
        width:100px!important;
    }
}
.card-content{
    @media(max-width:770px){
        width:50%!important;
        align-self:center;
    }
}


.card-title{
    position: relative;
    z-index: 10!important;

}

.logoBanner{
    width: 70%;
    align-content: center;
    text-align: center;


}


`