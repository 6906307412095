import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import * as FaIcons from "react-icons/fa"
import {Link} from "react-router-dom";
import {DataConsumer} from "../context";
//import {SidebarData} from "./SidebarData";


import printer from "../images/dtlab.png"
import bed from "../images/innocase2.png"
import penger from "../images/coins.png"
import råd from "../images/rådgivning.png"
//import hand from "../images/hand.png"
//import start from "../images/start.png"
import rapport from "../images/rapport.png"



function Sidebar() {


    const [offset, setOffset] = useState(0);
    const [color, setColor] = useState("black");

    useEffect(() => {
        window.onscroll = () => {
          setOffset(window.pageYOffset)}
      }, []);

      const settFarge = ()=>{
                  if(offset+50 > window.innerHeight && offset+50 < window.innerHeight*2){
            setColor("white")}

           else if(offset > window.innerHeight*2 && offset<window.innerHeight*3){
                setColor("black")}

                else if(offset > window.innerHeight*3 && offset < window.innerHeight*4.5){
                    setColor("white")}
                    
                    else if(offset > window.innerHeight*4.5 && offset < window.innerHeight*5.5){
                        setColor("black")}

                        else if(offset > window.innerHeight*5.5 && offset < window.innerHeight*6.5){
                            setColor("white")}
  
                        else{
                            setColor("black")}
      }
    
      useEffect(()=>{
          settFarge()
});

   

    return(
        
        <DataConsumer>
            {value =>{
                const {handleSidebar, sidebarOpen } = value;


            return (
        <StyledWrapper>
           
            <Link to="#" className={sidebarOpen? "menu-bars-active" : "menu-bars"} style={{color:color}}> 
                    <FaIcons.FaBars onClick={handleSidebar} />
                </Link>
                
                <div className={sidebarOpen ? "modalOverlay" : "closed"} onClick={handleSidebar}></div>


            <nav className={sidebarOpen ? "nav-menu-active" : "nav-menu"}>
                <ul onClick={handleSidebar} className="nav-menu-items">
               
        

                    <li className="nav-text" onClick={ ()=>{window.scrollTo(0,0);}}>
                        <div className="new">
                            <div className="iconWrap">
                            <FaIcons.FaHome/>
                            </div>
                            <span>Hjem</span>
                        </div>
                    </li>

                    <li className="nav-text" onClick={ ()=>{window.scrollBy(0,window.innerHeight*1-window.scrollY);}}>
                        <div className="new">
                            <div className="iconWrap">
                                <img src={bed} alt="printer" style={{height:"35px", filter:"brightness(0) invert(1)"}} />
                            </div>
                            <span>Bed 2054</span>
                        </div>
                    </li>

                    <li className="nav-text" onClick={ ()=>{window.scrollBy(0,window.innerHeight*2-window.scrollY);}}>
                        <div className="new">
                            <div className="iconWrap">
                            <FaIcons.FaInfo/>
                            </div>
                            <span>Om</span>
                        </div>
                    </li>

                    <li className="nav-text" onClick={ ()=>{window.scrollBy(0,window.innerHeight*3-window.scrollY);}}>
                        <div className="new">
                            <div className="iconWrap">
                                <img src={penger} alt="printer" style={{height:"35px", filter:"brightness(0) invert(1)"}} />
                            </div>
                            <span>Pengestøtte</span>
                        </div>
                    </li>
                    <li className="nav-text" onClick={ ()=>{window.scrollBy(0,window.innerHeight*4.5-window.scrollY);}}>
                        <div className="new">
                            <div className="iconWrap">
                                <img src={råd} alt="printer" style={{height:"35px", filter:"brightness(0) invert(1)"}} />
                            </div>
                            <span>Rådgivning </span>
                        </div>
                    </li>
                    <li className="nav-text" onClick={ ()=>{window.scrollBy(0,window.innerHeight*5.5-window.scrollY);}}>
                        <div className="new">
                            <div className="iconWrap">
                                <img src={printer} alt="printer" style={{height:"35px", filter:"brightness(0) invert(1)"}} />
                            </div>
                            <span>DT Lab </span>
                        </div>
                    </li>
                    <li className="nav-text" onClick={ ()=>{window.scrollBy(0,window.innerHeight*6.5-window.scrollY);}}>
                        <div className="new">
                            <div className="iconWrap">
                                <img src={rapport} alt="printer" style={{height:"35px", filter:"brightness(0) invert(1)"}} />
                            </div>
                            <span>Prosjektrapporter</span>
                        </div>
                    </li>

              
                  
                </ul>
            </nav>
        </StyledWrapper>
    );
   }}
        </DataConsumer>
    );
}
export default Sidebar





  
const StyledWrapper = styled.div`

position: relative;
z-index: 100;
.logoLink{
    height: 100%;
}
.logo{
    height:90%;
    align-items: center;
}

.navbar{
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-bars{
    z-index: 200;
    position: fixed;
    right:20px;
    top:0;
    font-size: 2.5rem;
    background: none;
   
    @media(max-width:700px){
        margin-left:0;
    }
}
.menu-bars-active{
    z-index: 200;
    position: fixed;
    right:20px;
    top:0;
    font-size: 2.5rem;
    background: none;
    color:white!important;


    @media(max-width:700px){
        margin-left:0;
    }
}




.nav-menu{
    padding-top:50px;
    background-color: #060b26;
    width:250px;
    height:100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top:0;
    right:-250px;
    transition: 250ms;
    z-index: 100!important;

    
    @media(max-width:700px){
        left:-250px;
    }


}
.nav-menu-active{
    padding-top:50px;
    z-index:190;
    background-color: #060b26;
    width:250px;
    height:100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top:0;
    right:0;
    transition: 250ms;
}
.modalOverlay{
    background-color: rgba(0, 0, 0, 0.6);
    width:      100%;
  height:     100%; 
  z-index:    150;
  top:        0; 
  left:       0; 
  position:   fixed;
}

.closed{
    display:none;
}

.nav-text{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0px 8px 8px;
    list-style:none;
    height: 60px;
    cursor: pointer;
}
.nav-bottom{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style:none;
    height: 60px;
}
.nav-bottom a{
    text-decoration: none;
    color:#f5f5f5;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 16px;
    border-radius: 4px;
 

}



.nav-text a{
    text-decoration: none;
    color:#f5f5f5;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 16px;
    border-radius: 4px;

}

.new{
    text-decoration: none;
    color:#f5f5f5;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 16px;
    border-radius: 4px;
}

.new:hover{
    background-color:#1a83ff;


}
.iconWrap{
    font-size: 25px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right:10px;
}

.nav-text a:hover{
    background-color:#1a83ff;

}
.nav-bottom a:hover{
    background-color:#1a83ff;

}

.nav-menu-items{
    width:100%;
    padding:0;
}



span {
  margin-right: 20px;
}
`
