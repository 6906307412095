import React from 'react'

export default function BliCasebedrift() {
    return (
 
            <div className="container text-dark rounded p-5" >
                <div className="d-flex flex-row-reverse"><button type="button" class="btn-close" aria-label="Close"></button>
</div>
                <h1>Bli casebedrift i BED-2054 Innovasjon i praksis</h1>
                <h5>Hva betyr det å være casebedrift i BED2054 Innovasjon i praksis</h5>
                <p>Å være casebedrift i BED2054 Innovasjon i praksis betyr at din bedrift stiller et reellt problem/behov/mulighet til "rådighet" for studentene i faget. Gjennom semesteret vil deretter en tverrfaglig studentgruppe jobbe med innovasjonsmetodikker som "Design Thinking" og "Lean Startup", og bruke din bedrifts case som utgangspunkt i dette arbeidet. Studentene vil gjøre en rekke "brukerundersøkelser" gjennom prosjektet, og de vil generere ideer basert på denne læringen. Videre vil de prototype og teste flere av ideene. Arbeidet avsluttes med en presentasjon (for studentene en offisiell eksamen) foran din bedrift.</p>

                <h5>Hvilken type case kan studentene jobbe med?</h5>
                <p>Studenter kan jobbe med vidt forskjellige caser. Det er dog viktig at caser ikke defineres for "smalt", men at de er såpass generelle at det er rom for innovasjon og kreativitet i arbeidet. Ofte vil en typisk case handle om f. eks "hvordan nå ut i marked X med produkt Y", "hvordan gjøre et kommersielt produkt/tjeneste ut av teknologi Z" eller "hvordan markedsføre produkt/tjeneste C til målgruppe B". Fagansvarlige for BED-2054 Innovasjon i praksis kan bistå i utformingen av casen.</p>

                <h5>Hvilken verdi gir det oss som bedrift å være casebedrift? </h5>
                <p>Å være casebedrift i BED-2054 Innovasjon i praksis er en fantastisk mulighet. Du vil få:</p>
                <ul>
                <li>Ny og dypere innsikt i dine (potensielle) brukere og kunder</li>
                <li>En rekke nye ideer til løsninger på ditt problem/behov/mulighet</li>
                <li>En eller flere testede ideer, inkludert tidlige tilbakemeldinger fra (potensielle) brukere og kunder</li>
                <li>En muntlig presentasjon fra din studentgruppe, samt skriftlig rapport innlevert i etterkant</li>
                <li>Innsikt i nye, relatante og praktiske innovasjonsmetoder som "design thinking" og "lean startup"</li>
                <li>Rettigheter til alle ideer/prototyper som studentene utarbeidet for din bedrift</li>
                <li>Muligheten til å gi studenter på UiT et innblikk i din bedrift, samt mulighet for å rekruttere studenter i etterkant</li>
                </ul>

                <h5>Hva kreves av oss som bedrift? </h5>
                <p>Det er i første omgang gratis å delta som casebedrift, men vi krever minimum 15 timer i egeninnsats til følgende:</p>
                <ul>
                <li>Dialog med fagansvarlige for utarbeidelse av case (ca. 2 timer)</li>
                <li>Caseintroduksjon/bedriftsbesøk med studentgruppe (ca. 2 timer)</li>
                <li>Tilgjengelighet for studenter underveis i prosjektet (bistand, oppklaringer, kontakter osv.) (ca. 8 timer)</li>
                <li>Deltakelse på avsluttende presentasjon/eksamen (ca. 2 timer)</li>
                <li>Feedback-møte med fagansvarlige i etterkant av prosjektet (ca. en time)</li>
                </ul>

                <h5>Hvilke type studenter kan vi forvente på vår case?</h5>
                <p>Et viktig poeng med BED-2054 Innovasjon i praksis er tverrfaglighet. Emnet er åpent for studenter fra alle fakulteter. I første omgang har vi rettet markedsføringen mot studenter fra Handelshøgskolen, Teknologi (NT-Fak) og Psykologi, og man kan dermed forvente innslag av studenter fra disse fagmiljøene. Faget undervises på norsk.</p>
                
                <h5>Eksempler på andre bedrifter som har vært casebedrift i lignende opplegg tidligere? </h5>
                <p>Gjennom BED-2033 Entrepreneurship og BED-3048 Corporate Entrepreneurship har de fagansvarlige for BED-2054 Innovasjon i praksis jobbet med en rekke bedrifter på delvis lignende samarbeid og prosjekter tidligere. Casebedrifter her har inkludert: TUIL AS, Norges arktiske studentsamskipnad, Marealis, Kongsberg Satelitte Services, Drytech, Tromsø Idrettslag, Olivita, Remiks, Marine BioExploitation, Kvalvik Bait med fler. Tidligere casebedrifter er velkomne til å delta igjen.</p>
            
                <h5>Hvordan bli casebedrift? </h5>
                <p>Send en epost til:</p>
                <li>Håvar Brattli / Havar.brattli@uit.no</li>
                <li>Alexander Utne / Alexander.utne@uit.no</li>

            
            </div>
 
    )
}
