import React, { useState } from 'react';
import styled from "styled-components";
import DownArrows from "../components/DowArrows";
import pitch from "../images/pitch.jpg";
import test from "../Søknadsskjema.docx";
import StudentBedrifter from "../modal/StudentBedrifter";

export default function Pengestøtte() {

    
    const [open, setOpen] = useState(true);
    const toggle = ()=>{setOpen(!open)}

    return (
        <StyledWrapper>
        <div className="container">
            <div className="row  py-5">
                <div className="col">
                <h1 className="">ENTREPRENØRSKAPSFONDET V/ UiT</h1>
                <div className="row ">
                    <div className="col text-center mt-4">                
                        <button className="btn btn-outline-light" onClick={toggle}>VI HAR DELT UT 545 000 KR TIL 21 SPENNENDE UI STARTUPS</button>
                    </div>
                </div>

                <div className={open ? "closed" : "modalOverlay"} onClick={toggle}>
            <div className={open ? "closed" : "modalBox"} >
                <StudentBedrifter/>
                </div>
            </div>
               
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <p>Entreprenørskapsfondet er et lavterskel-fond rettet mot studenter ved UIT med mål om at det skal bidra til at flere studenter "kommer i gang" med sine potensielle startup-prosjekter. Fondet er finansiert av Sparebank1 Nord-Norge  og administreres av Handelshøgskolen ved UiT.</p>
                </div>
            </div>

            <a href="https://uit.no/nyheter/artikkel?p_document_id=625874&p_dim=88106&fbclid=IwAR3N9xzaRavQiNCAlqS_l50n6J-MXXHr05r-tIhotbxhrpo064CaC6L-QYk"><h4>Les UiTs egen nyhetssak om Entreprenørskapsfondet HER! </h4></a>
            <a href="https://radio.nrk.no/serie/distriktsprogram-troms/sesong/201904/DKTR01007519#t=2h14m16.24s"><h4>Hør oss snakke om Entreprenørskapsfondet på NRK Radio HER!  </h4></a>


            <div className="row my-4">
                <div className="col">
                    <h1> Diverse info om fondet</h1>
                </div>
            </div>


            <div className="row my-4">
                <div className="col">
                   
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-6">
                <p><strong>Hvor mye kan du søke om?</strong> Vi kan finansiere opptil 25.000,- kr per prosjekt  Det er ingen krav om egeninnsats, og UiT tar ingen eierandeler i prosjektet.</p>
                    
                    <p><strong>Hvem kan søke?</strong> Alle aktive UIT studenter kan søke (uansett campus).</p>
                    <p><strong>Hva kan pengene brukes til?</strong> Formålet med fondet er å hjelpe ideer videre og søkere må vise til konkrete tiltak/aktiviteter de ønsker å gjennomføre. Ofte vil dette være aktiviteter relatert til utvikling av ide, prototyping, undersøkelser og testing av markedsbehov m.m. </p>
                    <p><strong>Hvordan fungerer det?</strong> Søkere vil få tilbud om å presentere prosjektene sine foran et lite og uformelt panel. I løpet av 15 minutteres presenteres prosjektet kort og søker får umiddelbart tilbakemelding og kommentarer fra panelet. I løpet av neste dag får søker svar på hvorvidt prosjetket innvilges støtte.</p>
                 

                </div>
                <div className="col-sm-12 col-md-6">
                    <img src={pitch} alt="img" className="img"/>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <p><strong>Viktige momenter for søknaden/presentasjon:</strong></p>
              
                <p>Gjennomføringsevne er viktig.</p>
                <p>Penger kan ikke gå til lønn.</p>
                <p>Har søker en konkret plan for hva han/hun skal bruke penger på? Er denne planen god?</p>
                <p>Hvis du har mottatt annen statlig støtte tidligere (f.eks. forskningsrådet Stud-ENT, IN kommersialiseringstilskudd) kan det diskvalifisere deg for støtte.</p>
                <p>Spørsmål ang. søknad kan sendes til idegeneratoren@uit.no</p>
               

              
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <p><strong>Søknadsfrist: kommer</strong></p>
                    <p><strong>Presentasjoner: kommer</strong></p>
                </div>
            </div>

            <p>Du kan søke til fondet ved å <a href={test} download>Laste ned</a> søknadsskjema og send det til Alexander.utne@uit.no</p>



        </div>

        <div className="bottomArrow" onClick={ ()=>{
          window.scrollBy(0,window.innerHeight*4.5-window.scrollY);
        }}>
  <DownArrows cName="arrowsWhite"/>
    </div>


     
        </StyledWrapper>
    )
}


const StyledWrapper = styled.div`
overflow-x:hidden;
background-color:#033348;
min-height:150vh;
color:white;
@media(max-width:992px){
    height: auto;
}



.bottomArrow{
    position: absolute;
    bottom:-350vh;
    margin-left: 50%;

    @media(max-width:992px){
    display: none;
}
}

.img{
    max-width: 100%;
}


.btn{
    border-radius: 40px!important;

}
a{
    color:gray;
    }


    .modalBox{
  background-color: white;
  width: 900px;
  max-width: 100%;
  height: 800px;
  max-height: 100%;
  position: fixed;
  z-index: 500; 
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
}

.closed{
    display: none;
}

.modalOverlay{
    background-color: rgba(0, 0, 0, 0.6);
    width:      100%;
  height:     100%; 
  z-index:    400;
  top:        0; 
  left:       0; 
  position:   fixed;
 
}

.obs{
    color:yellow;
}
`