import React, { useState } from 'react';import styled from "styled-components";
import bed2054img from "../images/bed2054img.jpg";
import DownArrows from "../components/DowArrows";
import BliCasebedrift from "../modal/BliCasebedrift";


export default function InnovasjonIPraksis() {


    const [open, setOpen] = useState(true);
    const toggle = ()=>{setOpen(!open)}

 

    return (
        <>
        <StyledWrapper>
        <div className="container">
            <div className="row  pt-5">
                <div className="col">
                <h1 className="">BED2054 - Innovasjon i praksis</h1>
                <p>Innovasjon i praksis er et nytt og spennende bacheloremne tilgjengelig for UiT studenter på alle fakulteter og institutter! I faget vil du som student jobbe i en gruppe med andre studenter, hvor dere først skal velge en av våre reelle caser fra næringslivet, som dere deretter skal jobbe med i løpet av semesteret. Metodikken det jobbes etter er hovedsakelig "Design Thinking" og "Lean Startup", og dere vil tilbringe tid også utenfor campus i form av bedriftsbesøk, undersøkelser og tester, intervjuer og potensielle reiser m.m. Dere vil også ha et eget budsjett til prototyping og testing. Faget er lagt opp på en veldig praktisk måte, og det er ikke noe tradisjonelt "pensum" å lese på. Eksamen består av en presentasjon for ledelsen av bedriften, samt en gruppe- og individuell innlevering i etterkant. Faget er en spin-off fra BED2033 Entrepreneurship (bachelor) og BED3048 Corporate Entrepreneurship (master), og av erfaring vil det for de beste studentene kunne åpne seg muligheter for videre arbeid med bedriften i etterkant.  

</p>
                </div>
            </div>

            <div className="row ">
                <div className="col text-center my-4">
                    <button className="btn btn-outline-light m-3" onClick={toggle}>BLI CASEBEDRIFT / MER INFO HER</button>
                </div>
            </div>

            <div className={open ? "closed" : "modalOverlay"} onClick={toggle}>
            <div className={open ? "closed" : "modalBox"} >
                <BliCasebedrift/>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <h3>Kort om BED2054 - Innovasjon i praksis </h3>
                    <p><strong>Hva:</strong> Tverrfaglig og praktisk innovasjonsfag</p>
                    <p><strong>Metode:</strong> Implementere design thinking, lean startup osv. på reelle bedriftscaser.</p>
                    <p><strong>For hvem:</strong> Tilgjengelig for alle UiT studenter med valgfrie emner i studieløpet.</p>
                    <p><strong>Antall studiepoeng:</strong> 10</p>
                    <p><strong>Hovedforelesere og fagansvarlige:</strong> Håvar Brattli og Alexander Utne (begge Handelshøgskolen ved UiT)</p>
                    <p><strong>Semester:</strong> Vår og høst (oppstart høst 2018)
                    </p>

                </div>
                <div className="col-sm-12 col-md-6">
                    <img src={bed2054img} alt="img" className="img"/>
                </div>
            </div>
            

        </div>

        <div className="bottomArrow" onClick={ ()=>{
          window.scrollBy(0,window.innerHeight*2-window.scrollY);
        }}>
  <DownArrows cName="arrowsWhite"/>
    </div>


        </StyledWrapper>   
        </>
    )
}


const StyledWrapper = styled.div`
overflow-x:hidden;
background-color:#033348;
height:100vh;
color:white;
@media(max-width:992px){
    height: auto;
}



.bottomArrow{
    position: absolute;
    bottom:-100vh;
    margin-left: 50%;

    @media(max-width:992px){
    display: none;
}
}

.img{
    max-width: 100%;
}

.btn{
    border-radius: 40px!important;
}


.modalBox{
  background-color: white;
  width: 900px;
  max-width: 100%;
  height: 800px;
  max-height: 100%;
  position: fixed;
  z-index: 500; 
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
}

.closed{
    display: none;
}

.modalOverlay{
    background-color: rgba(0, 0, 0, 0.6);
    width:      100%;
  height:     100%; 
  z-index:    400;
  top:        0; 
  left:       0; 
  position:   fixed;

 
}
`