import React from 'react'
import styled from "styled-components";
import DownArrows from "../components/DowArrows";



export default function About() {

    
    return (  
    <StyledWrapper>
        <div className="container">
        <div class="wrapper">

            <div className="row my-5">
                <div className="col">
                <h1 className="">Om Idegeneratoren</h1>
                <p>«Idegeneratoren» er et prosjekt finansiert av Tromsø fylkeskommune. Målgruppen for prosjektet er studenter ved UiT Norges arktiske universitet og næringsliv i Troms og Tromsø-regionen. Gjennom prosjektet skal vi skape et nytt og spennende innovasjonstilbud i regionen, og aktivitetene skal supplere regionens eksisterende tilbud innenfor innovasjon og entreprenørskap. Prosjektets hovedmålsetning er som følger: <strong>Bidra til et mer innovativt og konkurransedyktig næringsliv i Troms og Tromsø-regionen.</strong></p>
                </div>
            </div>
            <div className="row my-5">
                <div className="col">
                    <h3 className="">
                    Spørsmål om prosjektet? Ta kontakt med våre prosjektledere.

                    </h3>
                </div>
            </div>
            <div className="row my-5">
                <div className="col">
                    <p>
                   <strong> Prosjektleder Håvar Brattli </strong>(Universitetslektor Handelshøgskolen v/ UiT)

                     havar.brattli@uit.no / 776 20 936

                    </p>
                    <p>

             
                <strong>Prosjektleder Alexander Utne </strong>(Universitetslektor Handelshøgskolen v/ UiT)

                    alexander.utne@uit.no / 776 46 042
                    </p>
                </div>
            </div>
            </div>
        </div>


        <div className="bottomArrow" onClick={ ()=>{
          window.scrollBy(0,window.innerHeight*3-window.scrollY);
        }}>
  <DownArrows cName="arrowsBlack"/>
    </div>

     
        </StyledWrapper>
    )
}



const StyledWrapper = styled.div`
height:100vh;
background-color: white;
@media(max-width:992px){
    height: auto;
}

.container{
height:100vh;

}

.wrapper{
    padding-top:20%;
}


.bottomArrow{
    position: absolute;
    bottom:-200vh;
    margin-left: 50%;

    @media(max-width:992px){
    display: none;
}}
`
