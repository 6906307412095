import React from 'react'
import styled from "styled-components";
import DownArrows from "../components/DowArrows";
import rapport from "../images/rapport.png";
import undersøkelse from "../rapporter/Hvordan påvirker studententreprenørskap studenten.pdf"
import ide from "../rapporter/Opprettelse av Idegeneratoren ved UIT.pdf"
import fond from "../rapporter/Opprettelse av Entreprenørskapsfondet ved UIT.pdf"
import eu from "../rapporter/Deltakelse i EU-prosjektet DiamonDT.pdf"
import bio from "../rapporter/Prosjektrapport Design thinking program for Biomarin industri.pdf"
import narfu from "../rapporter/Arbeidsnotat prosjekt med NArFU.pdf"
import dtlab from "../rapporter/Utvikling av The Lab for Design Thinkers (DT Lab).pdf"


export default function Rapporter() {

    
    return (  
    <StyledWrapper>
        <div className="container">
        <div class="wrapper">

            <div className="row my-5">
                <div className="col">
                <h1 className="">Prosjektrapporter</h1>
                </div>


                
            <div className="row">
                <div className="col-sm-12 col-md-6">
                <ul>
                    <li><a href={undersøkelse} download>Undersøkelse - Hvordan påvirker studententreprenørskap studenten</a></li>
                    <li><a href={ide} download>Rapport - Opprettelse av Idegeneratoren ved UIT.png</a></li>
                    <li><a href={fond} download>Rapport - Opprettelse av Entreprenørskapsfondet ved UIT.pdf</a></li>
                    <li><a href={eu} download>Rapport - Deltakelse i EU-prosjektet DiamonDT.pdf</a></li>
                    <li><a href={bio} download>Rapport - Prosjektrapport Design thinking program for Biomarin industri.pdf</a></li>
                    <li><a href={narfu} download>Arbeidsnotat - Prosjekt med NArFU.pdf</a></li>
                    <li><a href={dtlab} download>Rapport - Utvikling av The Lab for Design Thinkers (DT Lab).pdf</a></li>







                </ul>
      



                </div>
                <div className="col-sm-12 col-md-6">
                    <img src={rapport} alt="img" className="img"/>
                </div>
            </div>



                </div>

            
            </div>
        </div>


        <div className="bottomArrow" onClick={ ()=>{
          window.scrollBy(0,window.innerHeight*5.5-window.scrollY);
        }}>
  <DownArrows cName="arrowsBlack"/>
    </div>

     
        </StyledWrapper>
    )
}



const StyledWrapper = styled.div`
height:100vh;
background-color: white;
@media(max-width:992px){
    height: auto;
}

.container{
height:100vh;

}

.wrapper{
    padding-top:20%;
}


.bottomArrow{
    position: absolute;
    bottom:-450vh;
    margin-left: 50%;

    @media(max-width:992px){
    display: none;
}}

.img{
    max-width: 100%;
}

li{
    margin-bottom:10px; ;
}
`
