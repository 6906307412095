import React from 'react'
import styled from "styled-components";
import DownArrows from "../components/DowArrows";
import coffee from "../images/coffee.png";


export default function About() {

    
    return (  
    <StyledWrapper>
        <div className="container">
        <div class="wrapper">

            <div className="row my-5">
                <div className="col">
                <h1 className="">Rådgivning / mentor</h1>
                </div>


                
            <div className="row">
                <div className="col-sm-12 col-md-6">
                <p>Alle UiT-studenter som jobber med startup-prosjekter får tilbud om veiledning fra mentorer på Handelshøgskolen. Rådgivningen skjer i en uformell setting, ofte over en kopp kaffe, og alt som prates om skjer kondidensielt. Vår rolle i veiledningen er å være en sparringspartner på prosjektet ditt, komme med råd om ideen(e) og nye gi våre perspektiver på disse, samt hjelpe deg å strukturere jobben videre. Samtaler handler ofte om hvordan verifisere ideen og brukerbehov, hvordan prototype, teste og eksperimentere rundt konseptet ditt og/eller hvordan komme i kontakt med riktige partnere og andre ressurser som man finner nødvendig. I tillegg til Entreprenørskapsfondet
kan vi også bistå i søknadsprosesser til Innovasjon Norge og
Forskningsrådet hvis prosjektet ditt er i en fase hvor det trenger
finansiering.  

Vi håper å høre fra deg! Send oss en mail i dag :)

idegeneratoren@uit.no</p>

                </div>
                <div className="col-sm-12 col-md-6">
                    <img src={coffee} alt="img" className="img"/>
                </div>
            </div>



                </div>

            
            </div>
        </div>


        <div className="bottomArrow" onClick={ ()=>{
          window.scrollBy(0,window.innerHeight*5.5-window.scrollY);
        }}>
  <DownArrows cName="arrowsBlack"/>
    </div>

     
        </StyledWrapper>
    )
}



const StyledWrapper = styled.div`
height:100vh;
background-color: white;
@media(max-width:992px){
    height: auto;
}

.container{
height:100vh;

}

.wrapper{
    padding-top:20%;
}


.bottomArrow{
    position: absolute;
    bottom:-450vh;
    margin-left: 50%;

    @media(max-width:992px){
    display: none;
}}

.img{
    max-width: 100%;
}
`
