import React from 'react';
import styled from "styled-components";
import DownArrows from "../components/DowArrows";
import dtlabfake from "../images/dtlab.jpg";


export default function InnovasjonIPraksis() {

 

    return (
        <>
        <StyledWrapper>
        <div className="container">
            <div className="wrapper">
            <div className="row  pt-5">
            <div className="col-sm-12 col-md-6">
                <h1 className="">DT Lab</h1>
                <p>  DT Lab er et 60 m2 workshop space lokalisert på nye Handelshøgskolen (Breivangveien 23, Tromsø). DT Lab er et perfekt sted for idemyldring, visualisering og prototyping for ditt innovasjonsprosjekt. Vi har 3D printer, enorme whiteboards, digitale tavler og TV'er, samt diverse utstyr og materiale for prototyping. Som studentgründer på UiT kan du få access til lokalet 24/7 (forutenom tidspunkter hvor rommet brukes til undervisning, workshops og andre eventer). Ta kontakt med oss i dag på idegeneratoren@uit.no for personlig tilgang!  </p>
                </div>
          

            <div className="col-sm-12 col-md-6">
                    <img src={dtlabfake} alt="img" className="img"></img>

                </div>
  </div>


        
            </div>
            </div>

            
            

 
        <div className="bottomArrow" onClick={ ()=>{
          window.scrollBy(0,window.innerHeight*6.5-window.scrollY);
        }}>
  <DownArrows cName="arrowsWhite"/>
    </div>


        </StyledWrapper>   
        </>
    )
}


const StyledWrapper = styled.div`
background-color:#033348;
height:100vh;
color:white;
@media(max-width:992px){
    height: auto;
}



.bottomArrow{
    position: absolute;
    bottom:-550vh;
    margin-left: 50%;

    @media(max-width:992px){
    display: none;
}
}

.img{
    width:600px;
    max-width: 100%;
    max-height:100%;
}

.wrapper{
    padding-top:20%;
}


`