import React from 'react'
import medsensio from "../images/medsensio.png";
import keenius from "../images/keenius.png";
import pazzing from "../images/Pazzing.png";
import styled from "styled-components";


export default function BliCasebedrift() {
    return (
        <StyledWrapper> 
            <div className="container text-dark rounded p-5" >
                <div className="d-flex flex-row-reverse"><button type="button" class="btn-close" aria-label="Close"></button>
</div>
             <div className="row justify-content-between m-5">
                 <div className="col">
                     <h1>Noen av Start-up bedriftene ved UIT</h1>
                 </div>
             </div>
             <div className="row">
                 <div className="col"><a href="https://www.medsens.io/" target="blank"><img src={medsensio} className="img" alt="img"></img></a></div>
                 <div className="col"><a href="https://keenious.com/" target="blank"><img src={keenius} className="img" alt="img"></img></a></div>
                 <div className="col"><a href="https://playpazzing.com/" target="blank"><img src={pazzing} className="img" alt="img"></img></a></div>

             </div>

            
            </div>
            </StyledWrapper>

    )
}


const StyledWrapper = styled.div`
overflow-x:hidden;
max-width: 100%;

@media(max-width:597){
width: 300px;
}

.img{
    max-width: 100%;
}
`