const particlesConfig = {
  particles: {
    number: {
      value: 50,
      density: {
        enable: true,
        value_area: 800
      }
    },
    color: {
      value: "#033348"
    },
    shape: {
      type: "none",
      stroke: {
        width: 1,
        color: "#5b5891"
      },
      polygon: {
        nb_sides: 4
      },

    },
    opacity: {
      value: 0.1,
      random: false,
      anim: {
        enable: false,
        speed: 0.16241544246026904,
        opacity_min: 0.056845404861094156,
        sync: false
      }
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: true,
        speed: 7.308694910712106,
        size_min: 11.369080972218832,
        sync: false
      }
    },
    line_linked: {
      enable: true,
      distance: 176.3753266952075,
      color: "#a0a8cf",
      opacity: 0.8,
      width: 1.8
    },
    move: {
      enable: true,
      speed: 1.2,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 552.4033491425909,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: true,
        mode: "repulse"
      },
      onclick: {
        enable: true,
        mode: "push"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1
        }
      },
      bubble: {
        distance: 400,
        size: 20,
        duration: 2,
        opacity: 9,
        speed: 4
      },
      repulse: {
        distance: 200,
        duration: 0.4
      },
      push: {
        particles_nb: 4
      },
      remove: {
        particles_nb: 2
      }
    }
  },
  retina_detect: true
}

export default particlesConfig