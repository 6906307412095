import React from 'react';
import styled from "styled-components";

export default function DowArrows(props) {
    return (
        <StyledWrapper>
        <svg className={props.cName}>
							<path class="a1" d="M0 0 L30 32 L60 0" style={{animationDelay:"-1s"}} ></path>
							<path class="a2" d="M0 20 L30 52 L60 20" style={{animationDelay:"-0.5s"}}></path>
							<path class="a3" d="M0 40 L30 72 L60 40" style={{animationDelay:"0s"}}></path>
						</svg>
            
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
.arrows {
	width: 60px;
	height: 72px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 20px;
    
}
.arrowsBlack path {
	stroke: black;
	fill: transparent;
	stroke-width: 3px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
    cursor:pointer;

}

.arrowsWhite path {
	stroke: white;
	fill: transparent;
	stroke-width: 3px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
    cursor:pointer;

}




@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
}

.arrows path.a2 {
	animation-delay:-0.5s;
}

.arrows path.a3 {	
	animation-delay:0s;
}


`
