import React from 'react'
import Particles from "react-particles-js"
import ParticlesConfig from "./Config/ParticlesConfig";

export default function ParticelseBackground() {
    return (
        
<Particles  height="100vh" params={ParticlesConfig} />
      

        
    )
}
