import InnovasjonIPraksis from "./pages/InnovasjonIPraksis";
import HomeNew from './pages/HomeNew';
import About from './pages/About';
import Footer from "./Footer";
import Sidebar from "./components/Sidebar";
import Pengestøtte from "./pages/Pengestøtte";
import Mentor from "./pages/mentor";
import Dtlab from "./pages/Dtlab";
import styled from "styled-components"
import Downloads from "./pages/Downloads";
import Rapporter from "./pages/Rapporter"
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';
 



function App() {
  return (
       <StyledWrapper>
        
      <Router>       
          <Switch>
            <Route path="/downloads" element={<Downloads/>}/>
        </Switch>
      </Router>
    <Sidebar/>


      <HomeNew/>
      <InnovasjonIPraksis/>
      <About/>
      <Pengestøtte/>
      <Mentor/>
      <Dtlab/>
      <Rapporter/>
      <Footer/>

      <br></br>




    </StyledWrapper>
  );
}

export default App;

const StyledWrapper = styled.div`
html, body {
  overflow: hidden;
}
`


