import React from 'react'
import styled from "styled-components";
import { FaFacebookSquare } from "react-icons/fa";
import DownArrows from "./components/DowArrows";
import Søknad from "./Vedlegg 3 - Prosjektsøknad.pdf"
import Tilsagn from "./Vedlegg 1 – Tilsagn om tilskudd.pdf"



 

export default function Footer() {
    return (
        <StyledWrapper>



<div className="container">
    

 
        

       






            <div className="row justify-content-around m-0 pt-5">
                <div className="card col-12 col-md-3">
                    <div className="card-content"> <i className="fas fa-hand-holding-usd fa-3x"></i>
                        <img src="https://www.tffk.no/kunde/grafikk/logo_farge_web.svg" className="card-title" alt="img"/>
                       
                    </div>
                </div>
                <div className="card col-12 col-md-3">
                    <div className="card-content"> <i className="far fa-handshake fa-3x"></i>
                        <img src="https://uit.no/ressurs/uit/profil2019/navnetrekk/bokmal_main.svg" className="card-title" alt="img"/>
                      
                    </div>
                </div>
                <div className="card col-12 col-md-3">
                    <div className="card-content"> <i className="fas fa-mobile-alt fa-3x"></i>
                        <img  src="https://www.sparebank1.no/content/dam/SB1/ikoner/GUI-ikoner/logo-snn.svg" className="card-title" alt="img"/>
                      
                    </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col text-center">
                                            <a href="https://www.facebook.com/UITINNOVASJON"><FaFacebookSquare style={{fontSize:"45px"}} /></a>

                                 </div>

                    </div>
    
        
        </div></div>

<div className="bottomArrow" onClick={ ()=>{
          window.scrollTo(0,0);
        }}>
  <DownArrows cName="arrowsBlack"/>
    </div>

 <a href={Søknad} download>søknad</a>
   <a href={Tilsagn} download>Tilsagn</a>

        </StyledWrapper>
    )
}




const StyledWrapper = styled.div`
padding-top: 20%;

height:100vh;
background-color: white;
@media(max-width:992px){
    height: auto;
}

.bottomArrow{
    position: absolute;
    bottom:-650vh;
    margin-left: 35%;
    transform: rotate(180deg);


    @media(max-width:992px){
    display: none;
}
}

.card{
    border:none;
}

a{
    color:white;

}
`
